import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ImagesTop from "./ImagesTop";
export const _frontmatter = {
  "title": "Huey Mobile Privacy Policy"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page huey-mobile-privacy-policy-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Huey Mobile Privacy Policy`}</h1>
        <p>{`Last updated: 6th November 2021`}</p>
        <h2>{`Purpose`}</h2>
        <p>{`This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the App. By using the App, you agree to the collection and use of information in accordance with this policy.`}</p>
        <h2>{`Information Collection and Use`}</h2>
        <ol>
          <li parentName="ol">{`Your data is stored anonymously and separately from your identity.`}</li>
          <li parentName="ol">{`Your identity is determined by how you sign up e.g. email address, phone number, or Sign in with Apple (which keeps your email address private) and is only used for the purpose of authentication.`}</li>
          <li parentName="ol">{`Your physical address or specific location is never requested or captured - we only ask for the approximate area of your sensor.`}</li>
          <li parentName="ol">{`If you choose to make your data available to researchers, your data will always be anonymous.`}</li>
          <li parentName="ol">{`Water utilities may target messages to groupings that include you via the app, but they will not have your identity or specific location.`}</li>
          <li parentName="ol">{`Your email address may be used by us to contact you for notification, authentication, information or marketing purposes.`}</li>
          <li parentName="ol">{`Your identity or contact information is never shared with any third party.`}</li>
        </ol>
        <h2>{`Log Data`}</h2>
        <p>{`Like many app operators, we collect information that your mobile device sends whenever you open our App ("Log Data"). This Log Data may include information such as your mobile device's Internet Protocol  ("IP") address, make and model, the pages of our App that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this.`}</p>
        <h2>{`Communications`}</h2>
        <p>{`We may use your email address to contact you with newsletters, marketing or promotional materials, however you will be given the  opportunity to opt out.`}</p>
        <h2>{`Security`}</h2>
        <p>{`The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of  electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot  guarantee its absolute security.`}</p>
        <h2>{`Changes to this Privacy Policy`}</h2>
        <p>{`This Privacy Policy is effective as of 6th November 2021 and will remain in effect except with respect to any changes
in its provisions in  the future, which will be in effect immediately after being posted on this page.
We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page
will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified
Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you either through the
email address you have provided us, or by placing a prominent notice on our website.`}</p>
        <h2>{`Contact Us`}</h2>
        <p>{`If you have any questions about this Privacy Policy, please contact us.`}</p>
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      